import Vue from 'vue'
import App from './App.vue'
import router from "./routers/config"
import "./animation/animation.scss"
import "@/utils/rem"
import 'animate.css';
import store from './store'
Vue.config.productionTip = false
import {
  Select, Input, Upload, Button, Icon, Popover, Option, Badge, Message, Dropdown,
  DropdownMenu,
  DropdownItem,
  Radio,
  Carousel,
  CarouselItem,
  Checkbox,
  Dialog
} from 'element-ui';
Vue.use(Select);
Vue.use(Input);
Vue.use(Upload);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Popover);
Vue.use(Option);
Vue.use(Badge);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Radio);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Checkbox);
Vue.use(Dialog);
Vue.prototype.$message = Message;
// 倒入工具函数
import * as Tool from "./utils/mixin"
Vue.prototype.$Tool = Tool;
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
