import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

Vue.use(Vuex);

const ls = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  encryptionSecret: "syntun"
});
export default new Vuex.Store({
  state: {
    loginState: false,
  },
  mutations: {
    EditLoginState (state, change) {
      state.loginState = change
    },
  },
  actions: {},
  modules: {
  },
  plugins: [
    createPersistedState({
      key: "syntun",
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ]
});