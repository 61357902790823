/**
 * @description 创建a链接跳转
 * @param 
 */
export const newAskit = (url) => {
  var a = document.createElement('a')
  let appDom = document.getElementById('app')
  a.setAttribute('href', url)
  a.setAttribute('target', '_blank')
  a.setAttribute('id', 'js_a')
  //防止反复添加
  if (document.getElementById('js_a')) {
    appDom.removeChild(document.getElementById('js_a'))
  }
  appDom.appendChild(a)
  a.click()
}