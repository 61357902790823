export default {
  /**
   * @description 配置显示在浏览器标签的title
   */
  title: "officialwebsite",
  /**
   * @description api请求基础路径
   */
  baseUrl: process.env.NODE_ENV === 'development' ? '/apis' : process.env.VUE_APP_BASE_URL,
};
