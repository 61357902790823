import Vue from 'vue';
import axios from "axios";
import {
  Message
} from 'element-ui';
import store from "@/store";
import config from "@/config";
import router from "@/routers/config"

const baseUrl = config.baseUrl;
axios.defaults.withCredentials = true; //让ajax携带cookie
class HttpRequest {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.queue = {};
  }
  //默认配置公共参数
  getInsideConfig() {
    const config = {
      baseURL: this.baseUrl,
      headers: {},
    };
    return config;
  }
  //根据code 执行不通操作
  codeState(data) {
    //当返回的是
    if (typeof data === 'string') return {
      code: 200,
      msg: '成功',
      body: data
    };
    let {
      code,
      msg
    } = data;
    if (parseInt(code) === 200) {
      data.msg = "成功";
      return data;
    } else if (code === 223) {
      return data;
    } else if (code === 217) {
      Message({
        message: '请先登录！',
        type: 'warning'
      });
      store.commit("EditLoginState", false);
      //跳转到登录
      router.push({
        path: "/login"
      });
      return data;
    } else if (code === 500) {
      return data;
    } else {
      return data;
    }
  }

  // 请求拦截
  interceptors(instance, url) {
    instance.interceptors.request.use(
      config => {
        // if (Vue.prototype.$cookies.get('usertoken')) {
        //   config.headers['token'] = Vue.prototype.$cookies.get('usertoken');
        // }
        return config;
      },
      error => {
        return Proimse.reject(error);
      }
    );
    // 响应拦截
    instance.interceptors.response.use(
      res => {
        const {
          data,
          status
        } = res;
        let userToken = res.headers.token
        if (userToken) {
          Vue.prototype.$cookies.remove('usertoken')
          if (base == 'prod') { //正式环境区别处理
            Vue.prototype.$cookies.set('usertoken', userToken, null, null, '.syntun.cn')
          } else {
            Vue.prototype.$cookies.set('usertoken', userToken)
          }
        }
        if (status == 200) return Promise.resolve(this.codeState(data)); //状态操作
        else return Promise.reject(data);
      },
      error => {
        // let errorInfo = error.response;
        // if (!errorInfo) {
        //   const {
        //     request: { statusText, status },
        //     config
        //   } = JSON.parse(JSON.stringify(error));
        //   errorInfo = {
        //     statusText,
        //     status,
        //     request: { responseURL: config.url }
        //   };
        // }
        return Promise.reject(error);
      }
    );
  }

  //抛出调用方法
  request(options) {
    const instance = axios.create();
    options = Object.assign(this.getInsideConfig(), options);
    this.interceptors(instance, options.url);
    return instance(options);
  }
}

const feach = new HttpRequest(baseUrl);
export default feach;