import feach from "@/utils/request";
import { toFormData } from '@/utils/tools'
const Apis = {
  UserInfo: "/user/getInfo",
}
/**
 * @description 获取用户信息
 * @param 
 */
export const getUserInfo = params => {
  return feach.request({
    url: Apis.UserInfo,
    method: "GET",
    params
  });
};
/**
 * @description 获取个人中心用户信息
 * @param 
 */
export const getPersonalCenter = params => {
  return feach.request({
    url: "/userList/getUserList",
    method: "GET",
    params
  });
};
/**
 * @description 修改用户信息
 * @param 
 */
export const editUserInfo = data => {
  return feach.request({
    url: "/userList/update/UserList",
    method: "PUT",
    data
  });
};
/**
 * @description 修改用户名
 * @param 
 */
export const editUserName = params => {
  return feach.request({
    url: "/userList/update/syntunUserName",
    method: "PUT",
    params
  });
};
/**
 * @description 获取验证码
 * @param 
 */
export const getVerificationCode = params => {
  return feach.request({
    url: "/user/setCode",
    method: "GET",
    params
  });
};
/**
 * @description 绑定/修改手机号
 * @param 
 */
export const changePhone = params => {
  return feach.request({
    url: "/user/replacePhone",
    method: "post",
    params
  });
};
/**
 * @description 绑定/修改邮箱
 * @param 
 */
export const changeEmail = params => {
  return feach.request({
    url: "/user/replaceEmail",
    method: "post",
    params
  });
};
/**
 * @description 验证是本人
 * @param 
 */
export const isSelf = params => {
  return feach.request({
    url: "/user/checkCode",
    method: "GET",
    params
  });
};
/**
 * @description 注销账号
 * @param 
 */
export const setLogOff = params => {
  return feach.request({
    url: "/userList/unsubscribe",
    method: "GET",
    params
  });
};
/**
 * @description 上传头像
 * @param 
 */
export const upImg = data => {
  return feach.request({
    url: "/userList/update/file",
    method: "POST",
    data: toFormData(data), 
  });
};
/**
 * @description 获取注销验证码
 * @param 
 */
export const sendLogOffCode = params => {
  return feach.request({
    url: "/userList/unsubscribeCode",
    method: "GET",
    params
  });
};

/**
 * @description 是否可以跳转
 * @param 
 */
export const cangoOut = params => {
  return feach.request({
    url: "/dataMatrix/checkAuth",
    method: "GET",
    params
  });
};