<!-- 文件描述: 头部导航应用市场隐藏菜单 -->
<!-- 创建时间: 2023/01/11；创建人: 阿苏 -->
<!-- 最后修改时间: 2023/01/11；最后修改人: 阿苏-->
<template>
  <div class="hide-menu-box menus-item" @mouseover="showMenu">
    <p class=" hideen-menu-btn" :class="{ active: pageName.includes(tagName) }">
      应用市场
    </p>
    <div :class="{ 'hidden-menu': menuState, ceiling: ceiling }" v-show="menuState">
      <div class="menu-content">
        <!--  -->
        <div class="product-list">
          <!--  -->
          <!-- <div class="product-item">
            <div class="anLe">
              <div class="product" @click="skit('directionSYNLEN')">
                <div class="title">
                  <p>弦镜</p>
                  <i class="el-icon-right"></i>
                </div>
                <p class="info">生意搜索工具</p>
              </div>
            </div>
            <div class="product-details-list">
              <h3 class="title">宏观数据查看</h3>
              <div class="tag tag-hover" @click="skitExternalProject(lenTestURl, 'selBrands')">
                <div class="tagChild">排行榜</div>
              </div>
            </div>
            <div class="product-details-list">
              <h3 class="title">品类销售趋势</h3>
              <div class="tag tag-hover" @click="skitExternalProject(lenTestURl)">
                <div class="tagChild">品类模块</div>
              </div>
            </div>
            <div class="product-details-list">
              <h3 class="title">品牌排行榜</h3>
              <div class="tag tag-hover" @click="skitExternalProject(lenTestURl)">
                <div class="tagChild">热卖排行</div>
              </div>
            </div>
            <div class="product-details-list">
              <h3 class="title">全平台筛选</h3>
              <div class="tag tag-hover" @click="skitExternalProject(lenTestURl)">
                <div class="tagChild">综合列表</div>
              </div>
            </div>
          </div> -->

          <!--  -->
          <div class="product-item">
            <div class="anLe">
              <div class="product product2" @click="skit('directionDM')">
                <div class="title">
                  <p>DM</p>
                  <i class="el-icon-right"></i>
                </div>
                <p class="info">大数据分析产品</p>
              </div>
            </div>
            <!--  -->
            <div class="product-details-list">
              <h3 class="title">竞争格局趋势</h3>
              <div class="tag tag-hover" @click="goDm()">
                <div class="tagChild">查看竞争格局</div>
              </div>
            </div>
            <div class="product-details-list">
              <h3 class="title">量化分析指标</h3>
              <div class="tag tag-hover" @click="goDm()">
                <div class="tagChild">指标详情</div>
              </div>
            </div>
            <div class="product-details-list">
              <h3 class="title">全网促销策略</h3>
              <div class="tag tag-hover" @click="goDm()">
                <div class="tagChild">促销详情</div>
              </div>
            </div>
          </div>

          <!--  -->
          <!-- <div class="product-item">
            <div class="product product3" @click="skit('directionSS')">
              <div class="title">
                <p>SS</p>
                <i class="el-icon-right"></i>
              </div>
              <p class="info">大数据分析产品</p>
            </div>
            <div class="product-details-list">
              <h3 class="title">渠道信息汇总</h3>
              <p class="tag tag-hover" @click="skitExternalProject(ssTestUrl)">渠道查看</p>
            </div>
            <div class="product-details-list">
              <h3 class="title">违规情况透析</h3>
                <p class="tag tag-hover" @click="skitExternalProject(ssTestUrl)">分析违规</p>
              </div>
            </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  name: '',
  //import引入的组件需要注入到对象中才能使用
  props: {
    tagName: {
      type: String,
    },
    ceiling: {
      type: Boolean,
    },
  },
  components: {},
  data() {
    return {
      menuState: false,
      pageName: ['directionSYNLEN', 'directionDM', 'directionSS'],
      // lenTestURl: 'http://58.18.32.146:17009/#/',
      // dmTestUrl: 'https://dmtest.mycelnet.cn',
      // ssTestUrl: 'http://58.18.32.146:17004',
      lenTestURl: 'https://www.synlen.com/#/',
      dmTestUrl: 'https://service.syntun.com',
      ssTestUrl: 'https://service.syntun.com',
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    /**
     * @description 打开隐藏菜单
     * @param
     */
    showMenu() {
      this.menuState = true
    },
    /**
     * @description 选择切换页面
     * @param
     */
    skit(item) {
      this.$router.push(item)
      this.menuState = false
    },
    /**
     * @description 跳转外部项目
     * @param
     */
    skitExternalProject(url, page = '') {
      this.menuState = false
      let newUrl = url + page
      if (newUrl) {
        this.$Tool.newAskit(newUrl)
      }
    },
    //跳转dm详情页
    goDm() {
      this.menuState = false
      this.$router.push('/directionDM')
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入公共css类 */
.hide-menu-box {
  position: relative;

  .product-list {
    .product-item {
      display: flex;
    }
    .anLe {
      position: relative;
      &:hover {
        .product {
          right: -10px;
          transition: 0.5s;
          cursor: pointer;
        }
      }
    }
    .product {
      background: url('../../assets/direction/product-bg1.png') no-repeat center;
      background-size: 100% 100%;
      width: 220px;
      height: 130px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      right: 0;
      transition: all 0.3s;
      .title {
        font-size: 18px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #333;
        display: flex;
        width: 80%;
        justify-content: space-between;
        align-items: center;
      }

      .info {
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333;
        width: 80%;
        text-align: left;
        margin-top: 15px;
      }
    }

    .product2 {
      background: url('../../assets/direction/product-bg2.png') no-repeat center;
      background-size: 100% 100%;
    }

    .product3 {
      background: url('../../assets/direction/product-bg3.png') no-repeat center;
      background-size: 100% 100%;
    }
  }

  //
  .product-details-list {
    margin-top: 40px;
    margin-left: 90px;

    .title {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
      margin: 0;
      margin-bottom: 20px;
    }

    .tag {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #999999;
      text-decoration: none;
      width: 100px;
      height: 40px;
      text-align: center;
      position: relative;
      .tagChild {
        width: 100%;
        text-align: center;
        position: absolute;
        top: 0;
        transition: all 0.3s;
      }
    }
  }
}

.active {
  position: relative;
  color: #ff9900;
}

.hidden-menu {
  width: 100vw;
  position: absolute;
  z-index: 99;
  left: -280px;
  top: 78px;
  overflow: hidden;
  height: 252px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: hidden_menu;
  animation-duration: 1s;
  transition: top 0.3s;
  box-shadow: 10px 10px 8px 2px rgba(140, 96, 28, 0.2);

  .menu-content {
    width: 70vw;
    display: flex;
    line-height: 18px;
  }
}

.ceiling {
  top: 60px;
}

@keyframes hidden_menu {
  from {
    height: 1px;
  }

  to {
    height: 252px;
  }
}
</style>