import Layout from '../layout/layout.vue'
const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/home",
    component: Layout,
    children: [
      // 首页
      {
        path: "home",
        name: "home",
        component: () => import('@/views/home/index.vue')
      },
      // 应用市场 DM
      {
        path: "directionDM",
        name: "directionDM",
        component: () => import('@/views/direction/dmPage.vue')
      },
      // 应用市场 SS
      {
        path: "directionSS",
        name: "directionSS",
        component: () => import('@/views/direction/ssPage.vue')
      },
      // 应用市场 弦镜
      {
        path: "directionSYNLEN",
        name: "directionSYNLEN",
        component: () => import('@/views/direction/synlenPage.vue')
      },
      // 关于我们
      {
        path: "aboutUs",
        name: "aboutUs",
        component: () => import('@/views/aboutUs/index.vue')
      },
      // 提交建议
      {
        path: "help",
        name: "help",
        component: () => import('@/views/help/index.vue')
      },
      // 提交建议
      {
        path: "suggest",
        name: "suggest",
        component: () => import('@/views/suggest/index.vue')
      },
      //用户个人中心
      {
        path: "user",
        name: "user",
        component: () => import('@/views/user/index.vue')
      },
      //修改手机号
      {
        path: "editPhone",
        name: "editPhone",
        component: () => import('@/views/user/editPhone.vue')
      },
      //修改邮箱
      {
        path: "editEmail",
        name: "editEmail",
        component: () => import('@/views/user/editEmail.vue')
      },
      //绑定手机号
      {
        path: "bindingPhone",
        name: "bindingPhone",
        component: () => import('@/views/user/bindingPhone.vue')
      },
      //绑定邮箱
      {
        path: "bindingEmail",
        name: "bindingEmail",
        component: () => import('@/views/user/bindingEmail.vue')
      },
      //注销
      {
        path: "logoff",
        name: "logoff",
        component: () => import('@/views/user/logoff.vue')
      },
    ]
  },
  {
    path: "/login",
    name: "login",
    component: () => import('@/views/login/index.vue')
  },
]
export default routes;