<!-- 文件描述: 头部导航 -->
<!-- 创建时间: 2022/12/21；创建人: 阿苏 -->
<!-- 最后修改时间: 2022/12/21；最后修改人: 阿苏-->
<template>
  <div class='header-roter' :class="{ ceiling: ceiling }" @mouseleave="selectHide">
    <div class="content">
      <!-- 菜单 -->
      <div class="menus-box">
        <!-- logo -->
        <img src="../../assets/home/logo.png" class="logo" @click="skit('home')" alt="">
        <div class="tag-hover menus-item homeDiv" :class="{ active: tagName == 'home' }" @click="skit('home')">
          <div class="tagChild">首页</div>
        </div>
        <Select ref="select" :tagName="tagName" :ceiling="ceiling"></Select>
        <div class="tag-hover menus-item" :class="{ active: tagName == 'aboutUs' }" @click="skit('aboutUs')">
          <div class="tagChild">关于我们</div>
        </div>
        <div class="tag-hover menus-item" :class="{ active: tagName == 'help' }" @click="skit('help')">
          <div class="tagChild">帮助中心</div>
        </div>
        <div class="tag-hover menus-item" :class="{ active: tagName == 'suggest' }" @click="skit('suggest')">
          <div class="tagChild">提交建议</div>
        </div>
        <div class="tag-hover menus-item" @click="goCustomCenter()" v-if="purview && purview!='' && purview!=null">
          <div class="tagChild">交付中心</div>
        </div>
      </div>
      <!-- 用户信息 -->
      <div class="info-box">
        <!-- 消息 预留位置 -->
        <!-- <el-badge :value="12" class="item message ">
            <el-button type="text" class="tag-hover message-btn" size="small">消息</el-button>
          </el-badge> -->
        <el-dropdown v-if='LoginState' @command='handleCommand'>
          <div class="user" style='margin-right:40px;'>
            <div class="head-portrait">
              <img :src="headerImg" alt="" class="headerImg" v-if="headerImg && headerImg!='' && headerImg!=null">
              <img src="@/assets/user/header.png" alt="" class="headerImg" v-else>
            </div>
            <!-- <p class="user-name">{{ disposePhone(userName) }}</p> -->
            <!-- <p class="user-name">{{ userName }}</p> -->
            <p class="user-name" v-if="phone && phone!='' && phone!=null && phone!=undefined">{{ disposePhone(phone) }}</p>
            <p class="user-name" v-else>{{ disposePhone(email) }}</p>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command='user'>个人中心</el-dropdown-item>
            <el-dropdown-item command='logout'>退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <div v-else class="register" @click="skit({ path: 'login' })">注册/登录</div>
      </div>
    </div>

  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { setLogout } from '@/api/login'
import { getUserInfo, getPersonalCenter } from '@/api/user'
import Select from './Select.vue'
export default {
  name: '',
  //import引入的组件需要注入到对象中才能使用
  components: {
    Select,
  },
  props: {
    ceiling: {
      type: Boolean,
    },
  },
  data() {
    return {
      tagName: '',
      userName: '',
      headerImg: '',
      purview: '',
      phone: '',
      email: '',
    }
  },
  //监听属性 类似于data概念
  computed: {
    LoginState() {
      if (this.$store.state.loginState) {
        this.getUserInfo()
      }
      return this.$store.state.loginState
    },
  },
  //监控data中的数据变化
  watch: {
    $route: {
      handler(val) {
        this.tagName = val.name
        if (val.query.hasOwnProperty('t')) {
          this.getUserInfo()
        }
      },
      immediate: true,
    },
  },
  //方法集合
  methods: {
    /**
     * @description 选择切换页面
     * @param
     */
    skit(item) {
      this.selectHide()
      this.$router.push(item)
    },
    /**
     * @description 关闭隐藏菜单
     * @param
     */
    selectHide() {
      this.$refs['select'].menuState = false
    },

    /**
     * @description 用户下来菜单点击回调
     * @param key
     */
    handleCommand(key) {
      if (key == 'logout') {
        this.setLogout()
      } else if (key == 'user') {
        this.$router.push('/user')
      }
    },
    /**
     * @description 退出登录
     * @param
     */
    async setLogout() {
      try {
        let { code } = await setLogout()
        if (code == 200) {
          sessionStorage.removeItem('userName')
          this.userName = ''
          this.$store.commit('EditLoginState', false)
          this.$router.push('/home')
        }
      } catch (error) {
        console.error('error', error)
      }
    },
    /**
     * @description 获取用户信息
     * @param
     */
    async getUserInfo() {
      try {
        let { code, body } = await getPersonalCenter()
        if (code == 200) {
          this.userName = body.syntunUserName
          this.headerImg =
            !body.headPortrait || body.headPortrait == ''
              ? ''
              : process.env.VUE_APP_BASE_URL + body.headPortrait
          this.purview = body.purview
          this.phone = body.phone
          this.email = body.email
        }
      } catch (error) {
        console.error('获取用户信息', error)
      }
    },
    /**
     * @description 处理手机号
     * @param
     */
    disposePhone(phone) {
      if (!phone || phone == '') {
        return '*****'
      }
      return phone.substr(0, 3) + '****' + phone.substr(phone.length - 4)
    },
    // 跳转客户中心
    async goCustomCenter() {
      if (!this.$store.state.loginState) {
        //提示登录
        this.$message('请先登录')
        this.$router.push('/login')
        return
      }
      try {
        let { code } = await getUserInfo()
        console.log('body', code)
        if (code == 200) {
          let url =
            process.env.VUE_APP_BASE_URL + '/customerCenter/gotoExperience'
          var a = document.createElement('a')
          let appDom = document.getElementById('app')
          a.setAttribute('href', url)
          a.setAttribute('target', '_blank')
          a.setAttribute('id', 'js_a')
          //防止反复添加
          if (document.getElementById('js_a')) {
            appDom.removeChild(document.getElementById('js_a'))
          }
          appDom.appendChild(a)
          a.click()
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  mounted() {},
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入公共css类 */
.header-roter {
  width: 100vw;
  height: 80px;
  background: #ffffff;
  transition: all 0.3s;
  padding-right: 17px;
  box-sizing: border-box;

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.logo {
  width: 120px;
  height: 45px;
  margin-left: 40px;
  cursor: pointer;
}

.menus-box {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 18px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #333333;
  .homeDiv {
    // width: 40px !important;
  }
  .menus-item {
    // width: 80px;
    height: 80px;
    line-height: 80px;
    margin-left: 40px;
    // cursor: pointer;
    position: relative;
    .tagChild {
      // position: absolute;
      // top: 0;
      // transition: all 0.3s;
    }
  }
}

// 信息
.info-box {
  width: 40vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #333333;
  // margin-right: 40px;

  .message {
    margin-right: 20px;

    .message-btn {
      font-size: 18px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
    }
  }

  .user {
    display: flex;
    align-items: center;

    .head-portrait {
      width: 40px;
      height: 36px;
      background: #a6d4ae;
      border-radius: 50%;
      margin-right: 10px;
      .headerImg {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
  }

  .register {
    margin-left: 20px;
    width: 120px;
    height: 100%;
    line-height: 80px;
    background: #ff9900;
    font-size: 18px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
  }
}

.active {
  position: relative;
  color: #ff9900;
}

// 吸顶灯
.ceiling {
  position: fixed;
  box-shadow: 2px 2px 8px 2px rgba(140, 96, 28, 0.2);
  top: 0;
  transition: all 0.3s;
  height: 60px;
  z-index: 9;

  .menus-item {
    height: 60px !important;
    line-height: 60px !important;
    transition: all 0.3s;
  }

  .register {
    height: 60px !important;
    line-height: 60px !important;
    transition: all 0.3s;
  }

  .hidden-menu {
    top: 60px !important;
    transition: all 0.3s;
  }
}

.el-dropdown-menu {
  padding: 10px 20px !important;
}
</style>