/**
 * @param {*} num 个数
 * @description 随机生成校验码
 */
export const randomCode = num => {
  let codeStr = "abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789";
  let str = "";
  // 验证码有几位就循环几次
  for (var i = 0; i < num; i++) {
    var ran = getRandom(0, 60);
    str += codeStr.charAt(ran);
  }
  return str;
};
/**
 * @description 返回 n-m之间随机数
 */
export const getRandom = (n, m) => {
  n = Number(n);
  m = Number(m);
  // 确保 m 始终大于 n
  if (n > m) {
    var temp = n;
    n = m;
    m = temp;
  }
  // 下有详细说明
  return Math.floor(Math.random() * (m - n) + n);
};
/**
 * json 转 fromData 形式
 */
export function toFormData(params) {
  const formData = new FormData();
  Object.keys(params).forEach((key) => {
    formData.append(key, params[key]);
  });
  return formData;
}