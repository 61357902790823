import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routers";
import feach from "@/utils/request";
import store from "@/store";

Vue.use(VueRouter);
const router = new VueRouter({
  routes
});
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  // 需要主动触发登录的页面
  if (to.query.hasOwnProperty('authId')) {
    skipLogin(to.query.authId, next)
    return false
  } else {
    next()
  }
})
/**
 * @description 其他项目访问弦镜并需要跳过登录调用此函数
 * @param  token 跳过登录需要对应的令牌
 */
function skipLogin (token, callback) {
  feach.request({
    url: '/passport/login',
    method: "get",
    params: { authId: token }
  }).then(res => {
    if (res && res.code == 200) {
      store.commit('EditLoginState', true)
      callback('/home?t=1')
    } else {
      callback('/home')
    }
  })
}
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router;

