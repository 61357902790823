<!-- 文件描述: 公用底部菜单栏 -->
<!-- 创建时间: 2022/12/21；创建人: 阿苏 -->
<!-- 最后修改时间: 2022/12/21；最后修改人: 阿苏-->
<template>
  <div class='footer'>
    <div class="content">
      <!-- 服务特色 -->
      <div class="serve-feature">
        <div class="serve-feature-item" v-for="(item, index) in serviceList" :key="index" @click="goOut(item.url)">
          <img :src="item.icon" alt="">
          <div>
            <p class="title">{{ item.title }}</p>
            <p class="intro">{{ item.info }}</p>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="info">
        <div class="company">
          <div class="company-name">
            <img src="@/assets/home/favicon.png" class="color-card" />
            <div class="name">Syntun</div>
          </div>
          <div class="ip">www.syntun.com</div>
          <div class="site">北京市北京经济技术开发区科创十三街锋创科技园 8 号楼 5 层</div>
        </div>
        <div class="tag-box">
          <div class="title">技术支持与服务</div>
          <!-- <div class="tag tag-hover">
            <div class="tagChild">全天服务</div>
          </div> -->
          <div class="tag tag-hover" @click="skitPage('len')">
            <div class="tagChild">应用市场</div>
          </div>
          <!-- <div class="tag tag-hover">
            <div class="tagChild">服务案例</div>
          </div> -->
          <!-- <div class="tag tag-hover">应用市场</div>
          <div class="tag tag-hover">帮助中心</div>
          <div class="tag tag-hover">产品文档</div> -->
        </div>
        <div class="tag-box">
          <div class="title">账户管理</div>
          <!-- <div class="tag tag-hover">账号中心</div> -->
          <a href="/agreement.html" target="_blank" class="tag tag-hover">
            <div class="tagChild">隐私协议</div>
          </a>
          <div class="tag tag-hover" @click="openLicenseAgreement()">
            <div class="tagChild">授权协议</div>
          </div>
          <!-- <div class="tag tag-hover">发票索取</div> -->
        </div>
        <div class="tag-box">
          <div class="title">快速入口</div>
          <div class="tag tag-hover" @click="skit('dm')">
            <div class="tagChild">D-Matrix</div>
          </div>
          <!-- <div class="tag tag-hover" @click="skit('ss')">SS</div> -->
          <div class="tag tag-hover" @click="skit('len')">
            <div class="tagChild">弦镜</div>
          </div>
        </div>
        <div class="tag-box">
          <div class="title">社区</div>
          <div class="tag tag-hover" @click="goSuggestions()">
            <div class="tagChild">自助建议</div>
          </div>
          <!-- <div class="tag tag-hover">视频帮助</div>
          <div class="tag tag-hover">服务案例</div> -->
        </div>
        <div class="tag-box last">
          <div class="title" style="margin-bottom: 15px;">售前咨询</div>
          <!-- <div class="deep">010-53358400</div> -->
          <!-- <div class="tag tag-hover"> -->
          <div class="tag">
            <div class="tagChild">专业技术咨询</div>
          </div>
          <div class="tag">
            <div class="tagChild">全方位产品解读</div>
          </div>
          <div class="tag">
            <div class="tagChild">成熟解决方案</div>
          </div>
          <div class="tag">
            <div class="tagChild">成功客户案例分享</div>
          </div>
        </div>
      </div>
      <!-- 热门推荐 -->
      <div class="amity">
        <!-- <div class="deepen">热门推荐</div> -->
        <div class="deepen">友情链接</div>
        <div class="link tag-hover" @click="skit('dm')">
          <div class="tagChild">D-Matrix</div>
        </div>
        <div class="link tag-hover" @click="skit('len')">
          <div class="tagChild">弦镜</div>
        </div>
        <!-- <div class="link tag-hover" @click="skit('dm')">
          <div class="tagChild">SkyScope</div>
        </div> -->
      </div>
      <!-- 备案 -->
      <div class="filing">
        <a class="info-item" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11030102011679">
          <img src="@/assets/home/security-icon.png" alt="" style="margin-right: 2px; ;">
          <p style="margin-left:10px; ">京公网安备11030102011679号</p>
        </a>
        <a class="info-item" href="https://beian.miit.gov.cn"> 京ICP备14010051号-1 </a>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getUserInfo } from '@/api/user'
export default {
  name: '',
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {
      serviceList: [
        {
          title: '专业服务',
          info: '云服务全流程支持',
          icon: require('../../assets/home/footer_icon1.png'),
          url: 'aboutUs',
        },
        {
          title: '建议反馈',
          info: '优化改进建议',
          icon: require('../../assets/home/footer_icon2.png'),
          url: 'suggest',
        },
        {
          title: '安全',
          info: '数据安全稳定',
          icon: require('../../assets/home/footer_icon3.png'),
          url: 'aboutUs',
        },
        {
          title: '帮助中心',
          info: '知识库问答中心',
          icon: require('../../assets/home/footer_icon4.png'),
          url: 'help',
        },
      ],
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    goOut(url) {
      this.$router.push(url)
    },
    /**
     * @description 跳转产品
     * @param
     */
    async skit(type) {
      if (!this.$store.state.loginState) {
        //提示登录
        this.$message('请先登录')
        this.$router.push('/login')
        return
      }
      try {
        // let url = 'http://58.18.32.146:17065'
        // let url = 'https://code.mycelnet.cn'
        let url = process.env.VUE_APP_BASE_URL
        let { code } = await getUserInfo()
        if (code == 200) {
          switch (type) {
            case 'len':
              this.$Tool.newAskit(url + '/stringMirror/gotoExperience')
              break
            case 'dm':
              this.$Tool.newAskit(url + '/dataMatrix/gotoExperience')
              break
            case 'ss':
              this.$Tool.newAskit(url + '/skyScope/gotoExperience')
              break
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    /**
     * @description 项目内跳转
     * @param
     */
    skitPage(type) {
      switch (type) {
        case 'len':
          this.$router.push('directionSYNLEN')
          break
      }
    },
    //跳转自助建议页面suggest
    goSuggestions() {
      //如果当前已经是自助建议页面则不跳转
      if (this.$route.path === '/suggest') return
      this.$router.push('suggest')
    },
    //授权协议
    openLicenseAgreement() {
      window.open('http://msstatic.synlen.com/#/licenseAgreement')
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入公共css类 */
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}

.content {
  width: 62%;
}

.serve-feature {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.serve-feature-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    width: 70px;
    height: 70px;
    margin-right: 20px;
  }

  .title {
    font-size: 20px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
  }

  .intro {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    margin-top: 10px;
  }
}

// 公司
.info {
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  height: 303px;
  padding: 40px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.company {
  display: flex;
  flex-direction: column;
  width: 200px;
  align-items: flex-start;

  .company-name {
    display: flex;
    align-items: center;

    .name {
      font-size: 26px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #333333;
      margin-left: 20px;
      line-height: 24px;
    }
  }

  .color-card {
    width: 50px;
    height: 50px;
    // background: #FF9900;
    border-radius: 4px;
  }

  .ip {
    font-size: 20px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    margin: 20px 0;
  }

  .site {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    text-align: left;
  }
}

.tag-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // justify-content: space-between;

  .title {
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #333333;
    margin-bottom: 20px;
  }

  .tag {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    text-decoration: none;
    width: 130px;
    height: 40px;
    text-align: left;
    position: relative;
    .tagChild {
      position: absolute;
      top: 0;
      transition: all 0.3s;
    }
  }

  .deep {
    font-size: 20px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #333333;
    margin-bottom: 20px;
    height: 18px;
  }
}
.last {
  .tag {
    width: 200px;
  }
}
// 友商
.amity {
  display: flex;
  // justify-content: space-between;
  padding: 40px 20px;

  .deepen {
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #333333;
    margin-right: 30px;
  }

  .link {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    margin-left: 30px;
    width: max-content;
    height: 40px;
    text-align: center;
    // position: relative;
    .tagChild {
      width: max-content;
    }
  }
}

// 备案
.filing {
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #666666;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  .info-item {
    display: flex;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    text-decoration: none;
    align-items: center;
  }
  .ba {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      // width: 18px;
      // height: 18px;
      margin-right: 5px;
    }
  }
}
</style>