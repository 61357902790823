import feach from "@/utils/request";

const Apis = {
  SendCode: "/sendMail",
  Login: "/login", // 手机号登录
  QRCode: '/qrShow', // 获取登录二维码
  VerifyQRCode: "/stringLogin", // 校验是否扫码登录
  Logout: "logout", // 退出登录
}
/**
 * @description 发送验证码
 * @param 
 */
export const setSendCode = params => {
  return feach.request({
    url: Apis.SendCode,
    method: "GET",
    params
  });
};
/**
 * @description 获取二维码
 * @param 
 */
export const getQRCode = params => {
  return feach.request({
    url: Apis.QRCode,
    method: "GET",
    responseType: "blob",
    params
  });
};
/**
 * @description 获取二维码扫码结果
 * @param 
 */
export const getVerifyQRCode = params => {
  return feach.request({
    url: Apis.VerifyQRCode,
    method: "GET",
    params
  });
};
/**
 * @description 手机号登录
 * @param 
 */
export const setLogin = params => {
  return feach.request({
    url: Apis.Login,
    method: "get",
    params
  });
};
/**
 * @description 退出登录
 * @param 
 */
export const setLogout = params => {
  return feach.request({
    url: Apis.Logout,
    method: "get",
    params
  });
};
