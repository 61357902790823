<!-- 公共样式页 -->
<template>
  <div class='layout'>
    <Header :ceiling="ceiling"></Header>
    <div class="head-ceiling" v-show="ceiling"></div>
    <div class="main">
      <transition name="direction">
        <router-view class="direction-view" @link="link"></router-view>
      </transition>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Header from "./components/Header.vue"
import Footer from "./components/Footer.vue"
export default {
  name: '',
  //import引入的组件需要注入到对象中才能使用
  components: {
    Header,
    Footer
  },
  data () {
    return {
      ceiling: false
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //   监听滚动条，滚动到一定的距离，侧边栏和顶部导航就会定位
    handleScroll () {
      var topScroll = document.documentElement.scrollTop;
      // console.log('topScroll', topScroll);
      // 滚动距离大于多少时执行下面事件
      if (topScroll > 0) {
        this.ceiling = true;
      } else {
        // 小于的时候让他恢复原状
        this.ceiling = false;
      };
    },
    link () {
      console.log(1111);
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
}
</script>
<style scoped>
/* @import url(); 引入公共css类 */
.layout {
  background: #F8F8F8;
  overflow: hidden;

}


.direction-enter,
.direction-leave-to {
  opacity: 0;
}

.direction-view {
  transition: opacity 0.3s ease;
}

.head-ceilin {
  height: 80px;
  transition: height 0.3s ease;
}
</style>